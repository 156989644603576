import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
	{
		name: "home",
		path: "/",
		component: () => import("@/home.vue")
	},
	{
		path: "/get-started",
		name: "get-started-page-landing",
		props: true,
		component: () => import("@/pages/get-started.vue"),
		meta: {
			title: "Get Started"

		},
		children: [
			{
				name: "get-started-index-page",
				path: "",
				component: () => import("@/pages/get-started/index.vue"),
				meta: {
					title: "Get Started"
				}
			},
			{
				name: "get-started-design-page",
				path: "/get-started/design",
				component: () => import("@/pages/get-started/design.vue"),
				meta: {
					title: "Design",
					childOf: "get-started-page",
					subtitle: "Download all the latest design file and Bootstrap indication",
					iconClass: "bi-pencil",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "get-started-tokens-page",
				path: "/get-started/tokens",
				component: () => import("@/pages/get-started/tokens.vue"),
				meta: {
					title: "Tokens",
					childOf: "get-started-page",
					subtitle: "How to use them, plugins and setup indication to begin to draw",
					iconClass: "bi-puzzle",
					colorClass: "bg-red-100"
				}
			},
			{
				name: "get-started-install-page",
				path: "/get-started/install",
				component: () => import("@/pages/get-started/install.vue"),
				meta: {
					title: "Install",
					childOf: "get-started-page",
					subtitle: "Step-by-step guide to install and use our private NPM package",
					iconClass: "bi-box-seam",
					colorClass: "bg-yellow-100"
				}
			},
			{
				name: "get-started-develop-page",
				path: "/get-started/develop",
				component: () => import("@/pages/get-started/develop.vue"),
				meta: {
					title: "Develop",
					childOf: "get-started-page",
					subtitle: "All you need to setup you machine and where to find repositories",
					iconClass: "bi-code-slash",
					colorClass: "bg-green-100"
				}
			}
		]
	},
	{
		path: "/foundations",
		props: true,
		name: "foundations-page-landing",
		component: () => import("@/pages/foundations.vue"),
		meta: {
			title: "Foundations"
		},
		children: [
			{
				name: "foundations-index-page",
				path: "",
				component: () => import("@/pages/foundations/index.vue"),
				meta: {
					title: "Foundations"
				}
			},
			{
				name: "foundations-colors-page",
				path: "/foundations/colors",
				component: () => import("@/pages/foundations/colors.vue"),
				meta: {
					title: "Colors",
					childOf: "foundations-page",
					subtitle: "Theme, gradients, neutral colors, red shades, gray shades, valid, invalid",
					iconClass: "bi-palette2",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "foundations-fonts-typography-page",
				path: "/foundations/fonts-typography",
				component: () => import("@/pages/foundations/fonts-typography.vue"),
				meta: {
					title: "Typography",
					childOf: "foundations-page",
					subtitle: "Theme, gradients, neutral colors, red shades, gray shades, valid, invalid",
					iconClass: "bi-type-h1",
					colorClass: "bg-yellow-100"
				}
			},
			{
				name: "foundations-spaces-page",
				path: "/foundations/spaces",
				component: () => import("@/pages/foundations/spaces.vue"),
				meta: {
					title: "Spaces",
					childOf: "foundations-page",
					subtitle: "Theme, gradients, neutral colors, red shades, gray shades, valid, invalid",
					iconClass: "bi-grid-1x2",
					colorClass: "bg-red-100"
				}
			},
			{
				name: "foundations-shadows-page",
				path: "/foundations/shadows",
				component: () => import("@/pages/foundations/shadows.vue"),
				meta: {
					title: "Shadows",
					childOf: "foundations-page",
					subtitle: "Dark and red shadows",
					iconClass: "bi-layers-half",
					colorClass: "bg-yellow-100"
				}
			}
		]
	},
	{
		path: "/components",
		name: "components-page-landing",
		props: true,
		component: () => import("@/pages/components.vue"),
		meta: {
			title: "Components"

		},
		children: [
			{
				name: "components-index-page",
				path: "",
				component: () => import("@/pages/components/index.vue"),
				meta: {
					title: "Components"
				}
			},
			{
				name: "components-accordions-page",
				path: "/components/accordions",
				component: () => import("@/pages/components/accordions.vue"),
				meta: {
					childOf: "components-page",
					title: "Accordion",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					iconClass: "bi-box-arrow-in-up-right",
					colorClass: "bg-green-200",
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "components-buttons-page",
				path: "/components/buttons",
				component: () => import("@/pages/components/buttons.vue"),
				meta: {
					childOf: "components-page",
					title: "Buttons",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					iconClass: "bi-play-btn",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "components-badges-page",
				path: "/components/badges",
				component: () => import("@/pages/components/badges.vue"),
				meta: {
					childOf: "components-page",
					title: "Badges",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					iconClass: "bi-tag",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "components-search-page",
				path: "/components/search",
				component: () => import("@/pages/components/search.vue"),
				meta: {
					childOf: "components-page",
					title: "Search",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					iconClass: "bi-search",
					colorClass: "bg-green-100",
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "components-spinners-page",
				path: "/components/spinners",
				component: () => import("@/pages/components/spinners.vue"),
				meta: {
					title: "Spinners",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-hourglass-split",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "components-navbar-page",
				path: "/components/navbar",
				component: () => import("@/pages/components/navbar.vue"),
				meta: {
					title: "NavBar",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-list",
					colorClass: "bg-green-100",
					badgeVariant: "info",
					badgeTitle: "MPV"
				}
			},
			{
				name: "components-tabs-page",
				path: "/components/tabs",
				component: () => import("@/pages/components/tabs.vue"),
				meta: {
					title: "Navs & Tabs",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-segmented-nav",
					colorClass: "bg-green-100",
					badgeVariant: "info",
					badgeTitle: "MPV"
				}
			},
			{
				name: "components-filters-page",
				path: "/components/filters",
				component: () => import("@/pages/components/filters.vue"),
				meta: {
					title: "Filters",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-filter",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "components-thumbnails-page",
				path: "/components/thumbnails",
				component: () => import("@/pages/components/thumbnails.vue"),
				meta: {
					title: "Thumbnails",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-exclamation-circle",
					colorClass: "bg-green-100",
					badgeVariant: "info",
					badgeTitle: "MPV"
				}
			},
			{
				name: "components-checkbox-radio-page",
				path: "/components/checkbox-radio",
				component: () => import("@/pages/components/checkbox-radio.vue"),
				meta: {
					title: "Checkbox & Radio",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-check-square",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "components-input-page",
				path: "/components/input",
				component: () => import("@/pages/components/inputs.vue"),
				meta: {
					title: "Input",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-textarea-t",
					colorClass: "bg-green-100",
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "components-textarea-page",
				path: "/components/textarea",
				component: () => import("@/pages/components/textareas.vue"),
				meta: {
					title: "Textarea",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-textarea-resize",
					colorClass: "bg-green-100",
					badgeVariant: "info",
					badgeTitle: "MVP"
				}
				// FIXME: Tech Story 38771: Check if all TextArea functionalities are covered by Lexical
				/* children: [
					{
						name: "components-textarea-richtextarea-page",
						path: "/components/textarea#richtextarea",
						component: () => import("@/pages/components/textareas.vue"),
						meta: {
							title: "Rich Textarea",
							childOf: "components-textarea-page"
						}
					}
				] */
			},
			{
				name: "components-select-page",
				path: "/components/select",
				component: () => import("@/pages/components/selects.vue"),
				meta: {
					title: "Select",
					subtitle: "Select component is a versatile UX element that can be used in a variety of contexts to help users make choices and complete tasks more efficiently.",
					childOf: "components-page",
					iconClass: "bi-menu-down",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "components-shoes-size-selector-page",
				path: "/components/shoes-size-selector",
				component: () => import("@/pages/components/shoes-size-selector.vue"),
				meta: {
					title: "Shoes Size Selector",
					subtitle: "TbD",
					childOf: "components-page",
					iconClass: "bi-menu-down",
					colorClass: "bg-green-100",
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "components-modal-page",
				path: "/components/modal",
				component: () => import("@/pages/components/modal.vue"),
				meta: {
					title: "Modal",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-window-stack",
					colorClass: "bg-green-100",
					badgeVariant: "info",
					badgeTitle: "MPV"
				}
			},
			{
				name: "components-toast-page",
				path: "/components/toast",
				component: () => import("@/pages/components/toast.vue"),
				meta: {
					title: "Toast",
					subtitle: "A small, non-modal notification that appears on the screen to provide brief messages or alerts to the user",
					childOf: "components-page",
					iconClass: "bi-app-indicator",
					colorClass: "bg-green-100",
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "components-overlay-page",
				path: "/components/overlay",
				component: () => import("@/pages/components/overlay.vue"),
				meta: {
					title: "Overlay",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-fullscreen-exit",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "components-vertical-menu-page",
				path: "/components/vertical-menu",
				component: () => import("@/pages/components/vertical-menu.vue"),
				meta: {
					title: "Vertical Menu",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi-list-nested",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "components-functional",
				path: "/components/functional",
				component: () => import("@/pages/components/functional.vue"),
				meta: {
					title: "Functional Components",
					subtitle: "An extremely powerful utility belt to turn any Vue Component to a functional one, with full programmatic control.",
					childOf: "components-page",
					iconClass: "bi-box-arrow-in-up-right",
					colorClass: "bg-green-200"
				}
			},
			{
				name: "components-tables",
				path: "/components/tables",
				component: () => import("@/pages/components/tables.vue"),
				meta: {
					title: "Tables",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi bi-table",
					colorClass: "bg-green-100",
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "components-stepper",
				path: "/components/stepper",
				component: () => import("@/pages/components/stepper.vue"),
				meta: {
					title: "Stepper",
					subtitle: "",
					childOf: "components-page",
					iconClass: "bi bi-chevron-double-right",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "components-progress",
				path: "/components/progress",
				component: () => import("@/pages/components/progress.vue"),
				meta: {
					title: "Progress",
					subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
					childOf: "components-page",
					iconClass: "bi bi-table",
					colorClass: "bg-green-100"
				}
			},
			{
				name: "components-pagination-page",
				path: "/components/paginations",
				component: () => import("@/pages/components/paginations.vue"),
				meta: {
					title: "Pagination",
					subtitle: "An extremely powerful utility belt to turn any Vue Component to a functional one, with full programmatic control.",
					childOf: "components-page",
					iconClass: "bi bi-chevron-double-right",
					colorClass: "bg-green-100",
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "tree-view",
				path: "/components/tree-view",
				component: () => import("@/pages/components/tree-view.vue"),
				meta: {
					title: "Tree View",
					subtitle: "",
					childOf: "components-page",
					iconClass: "bi-box-arrow-in-up-right", // FIXME: #36039
					colorClass: "bg-green-200", // FIXME: #36039
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "input-duration",
				path: "/components/input-duration",
				component: () => import("@/pages/components/input-duration.vue"),
				meta: {
					title: "Input Duration",
					subtitle: "",
					childOf: "components-page",
					iconClass: "bi-box-arrow-in-up-right", // FIXME: #61798
					colorClass: "bg-green-200", // FIXME: #61798
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "input-time",
				path: "/components/input-time",
				component: () => import("@/pages/components/input-time.vue"),
				meta: {
					title: "Input Time",
					subtitle: "",
					childOf: "components-page",
					iconClass: "bi-box-arrow-in-up-right", // FIXME: #63379
					colorClass: "bg-green-200", // FIXME: #63379
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "input-date",
				path: "/components/input-date",
				component: () => import("@/pages/components/input-date.vue"),
				meta: {
					title: "Input Date",
					subtitle: "",
					childOf: "components-page",
					iconClass: "bi-box-arrow-in-up-right", // FIXME: #63387
					colorClass: "bg-green-200", // FIXME: #63387
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "input-dropdown",
				path: "/components/input-dropdown",
				component: () => import("@/pages/components/input-dropdown.vue"),
				meta: {
					title: "Input Dropdown",
					subtitle: "",
					childOf: "components-page",
					iconClass: "bi-box-arrow-in-up-right", // FIXME: #63387
					colorClass: "bg-green-200", // FIXME: #63387
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "input-tags",
				path: "/components/input-tags",
				component: () => import("@/pages/components/input-tags.vue"),
				meta: {
					title: "Input Tags",
					subtitle: "",
					childOf: "components-page",
					iconClass: "bi-box-arrow-in-up-right", // FIXME: #63395
					colorClass: "bg-green-200" // FIXME: #63395
				}
			},
			{
				name: "input-color",
				path: "/components/input-color",
				component: () => import("@/pages/components/input-color.vue"),
				meta: {
					title: "Input Color",
					subtitle: "",
					childOf: "components-page",
					iconClass: "bi-box-arrow-in-up-right", // FIXME: #62264
					colorClass: "bg-green-200", // FIXME: #62264
					badgeVariant: "info",
					badgeTitle: "MVP"
				}
			},
			{
				name: "text-editor",
				path: "/components/text-editor",
				component: () => import("@/pages/components/text-editor.vue"),
				meta: {
					title: "Text Editor",
					subtitle: "",
					childOf: "components-page",
					iconClass: "bi-box-arrow-in-up-right", // FIXME: #63371
					colorClass: "bg-green-200", // FIXME: #63371
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			},
			{
				name: "input-players",
				path: "/components/input-players",
				component: () => import("@/pages/components/input-players.vue"),
				meta: {
					title: "Input Players",
					subtitle: "",
					childOf: "components-page",
					iconClass: "bi-box-arrow-in-up-right", // FIXME: #63379
					colorClass: "bg-green-200", // FIXME: #63379
					badgeVariant: "warning",
					badgeTitle: "dev"
				}
			}
		]
	},
	{
		path: "/test-book",
		name: "test-book-page",
		component: () => import("@/pages/test-book.vue"),
		meta: {
			title: "Test book",
			disabled: true
		}
	},
	{
		path: "/brand",
		name: "brand-page",
		component: () => import("@/pages/brand.vue"),
		meta: {
			title: "Brand",
			disabled: true
		}
	}
];

const router = createRouter({
	history: createWebHistory(),
	linkActiveClass: "active",
	routes,
	scrollBehavior(to) {
		if (to.hash) {
			return {
				el: to.hash,
				behavior: "smooth"
			};
		}
		return {
			top: 0,
			left: 0,
			behavior: "instant"
		};
	}
});
router.beforeEach(async(to, from, next) => {
	// FIXME: Tech Story 44005: [DSy] Custom 404 page on Lighthouse docs
	// Handle 404 not found or generic error page, create a FIXME
	if (to.meta?.disabled)
		return;

	next();
});
export default router;
