import "./style.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";

import { useInfiniteScroll } from "@vueuse/core";
import { BAccordion, BAccordionItem, BAlert, type BaseButtonVariant, type BaseSize, BBadge, BBreadcrumb, BButton, BCard, BCardBody, BCardFooter, BCardHeader, BCardSubtitle, BCardText, BCardTitle, BCarousel, BCarouselSlide, BCol, BCollapse, BContainer, BDropdown, BDropdownItem, BImg, BLink, BNav, BNavbar, BNavbarBrand, BNavbarNav, BNavItem, BNavItemDropdown, BNavText, BOffcanvas, BootstrapVueOptions, BOverlay, BPopover, type Breakpoint, BRow, BSpinner, BTable, BTbody, BTd, BTh, BThead, BToast, BToastOrchestrator, BTr, createBootstrap, type PopoverPlacement, Size, type TableField, type TableFieldRaw, type TableItem, useBreadcrumb, useToastController, vBToggle } from "bootstrap-vue-next";
import { Field, FieldArray, Form, useField, useFieldArray, useForm } from "vee-validate";

import QFilterGroup from "./components/q-filter-group/q-filter-group.vue";
import { type Filter, type FilterObject } from "./components/q-filter-group/q-filter-group.vue";
import QFormFieldCheckbox from "./components/q-form-field-checkbox/q-form-field-checkbox.vue";
import QFormFieldColorPicker from "./components/q-form-field-color-picker/q-form-field-color-picker.vue";
import QFormFieldDate from "./components/q-form-field-date/q-form-field-date.vue";
import QFormFieldDropdown from "./components/q-form-field-dropdown/q-form-field-dropdown.vue";
import QFormFieldInput from "./components/q-form-field-input/q-form-field-input.vue";
import QFormFieldInputDuration from "./components/q-form-field-input-duration/q-form-field-input-duration.vue";
import QFormFieldInputNumber from "./components/q-form-field-input-number/q-form-field-input-number.vue";
import QFormFieldInputTags, { type TagState, type TagValidatorFunction } from "./components/q-form-field-input-tags/q-form-field-input-tags.vue";
import QFormFieldMultiselect, { type IOptionMultiselect } from "./components/q-form-field-multiselect/q-form-field-multiselect.vue";
import QFormFieldPlayers from "./components/q-form-field-players/q-form-field-players.vue";
import QFormFieldRadio from "./components/q-form-field-radio/q-form-field-radio.vue";
import { type IOption } from "./components/q-form-field-select/q-form-field-select.vue";
import QFormFieldSelect from "./components/q-form-field-select/q-form-field-select.vue";
import QFormFieldTextArea from "./components/q-form-field-textarea/q-form-field-textarea.vue";
import QFormFieldTextEditor from "./components/q-form-field-texteditor/q-form-field-texteditor.vue";
import QFormFieldTime from "./components/q-form-field-time/q-form-field-time.vue";
import QFormFieldViewWrapper from "./components/q-form-field-view-wrapper/q-form-field-view-wrapper.vue";
import QFormFieldWrapper from "./components/q-form-field-wrapper/q-form-field-wrapper.vue";
import QMenu from "./components/q-menu/q-menu.vue";
import { type MenuItem } from "./components/q-menu/q-menu-item.vue";
import QModal from "./components/q-modal/q-modal.vue";
import QModalConfirmDelete from "./components/q-modal-confirm-delete/q-modal-confirm-delete.vue";
import QModalSuccess from "./components/q-modal-success/q-modal-success.vue";
import QNav from "./components/q-nav/q-nav.vue";
import QNavbar from "./components/q-navbar/q-navbar.vue";
import QPagination, { type QPaginationAlignment } from "./components/q-pagination/q-pagination.vue";
import QProgress from "./components/q-progress/q-progress.vue";
import QStepper from "./components/q-stepper/q-stepper.vue";
import QTableSimple, { type IQTableField, type IQTableItem } from "./components/q-table-simple/q-table-simple.vue";
import QTabs from "./components/q-tabs/q-tabs.vue";
import { type TabItem } from "./components/q-tabs/q-tabs.vue";
import QThumbnail from "./components/q-thumbnail/q-thumbnail.vue";
import QTreeView, { type TreeItem } from "./components/q-tree-view/q-tree-view.vue";
import QTreeViewItem from "./components/q-tree-view/q-tree-view-item/q-tree-view-item.vue";
import Quantifier from "./components/quantifier/quantifier.vue";
import QShoeSizeSelector from "./components/shoe-size-selector/shoe-size-selector.vue";
import useComponentApp from "./utils/component-app";
import * as inputMask from "./utils/input-masks";
import { type QModalProps, useModalsUtilities } from "./utils/modals";
import { useBreakpoints, useResponsiveUtilities } from "./utils/responsive";
import useRule, { type AvailableRules } from "./utils/rules";
import { type ColorVariant, type CustomColorVariant } from "./utils/variant";
import useValidationI18n from "./utils/vee-validate-i18n";

export function createDsy(pluginData?: Readonly<BootstrapVueOptions>); // FIXME: #56616: [DSy] Discovery createDsy on main boot
export function createDsy(value: object = {}) {
	if ("plugins" in value)
		return createBootstrap(value.plugins as BootstrapVueOptions);
	return createBootstrap(value as BootstrapVueOptions);
}

// Types
export type {
	AvailableRules,
	BaseButtonVariant,
	BaseSize,
	Breakpoint,
	ColorVariant,
	CustomColorVariant,
	Filter,
	FilterObject,
	IOption,
	IOptionMultiselect,
	IQTableField,
	IQTableItem,
	MenuItem,
	PopoverPlacement,
	QModalProps,
	QPaginationAlignment,
	Size,
	TabItem,
	TableField,
	TableFieldRaw,
	TableItem,
	TagState,
	TagValidatorFunction,
	TreeItem
};

// Composables
export {
	inputMask,
	useBreadcrumb,
	useBreakpoints,
	useComponentApp,
	useField,
	useFieldArray,
	useForm,
	useInfiniteScroll,
	useModalsUtilities,
	useResponsiveUtilities,
	useRule,
	useToastController,
	useValidationI18n
};

// Directives
export {
	vBToggle as vQToggle
};

// FIXME: #51563 remove after QTable template implementation
const QTable = BTable;

export {
	// FIXME: #61674 remove after BNav and BNavbar template implementation
	BNav,
	BNavbar,
	BAccordion as QAccordion,
	BAccordionItem as QAccordionItem,
	BAlert as QAlert,
	BBadge as QBadge,
	BBreadcrumb as QBreadcrumb,
	BButton as QButton,
	BCard as QCard,
	BCardBody as QCardBody,
	BCardFooter as QCardFooter,
	BCardHeader as QCardHeader,
	BCardSubtitle as QCardSubtitle,
	BCardText as QCardText,
	BCardTitle as QCardTitle,
	BCarousel as QCarousel,
	BCarouselSlide as QCarouselSlide,
	BCol as QCol,
	BCollapse as QCollapse,
	BContainer as QContainer,
	BDropdown as QDropDown,
	BDropdownItem as QDropdownItem,
	Field as QField,
	FieldArray as QFieldArray,
	QFilterGroup,
	Form as QForm,
	QFormFieldCheckbox,
	QFormFieldColorPicker,
	QFormFieldDate,
	QFormFieldDropdown,
	QFormFieldInput,
	QFormFieldInputDuration,
	QFormFieldInputNumber,
	QFormFieldInputTags,
	QFormFieldMultiselect,
	QFormFieldPlayers,
	QFormFieldRadio,
	QFormFieldSelect,
	QFormFieldTextArea,
	QFormFieldTextEditor,
	QFormFieldTime,
	QFormFieldViewWrapper,
	QFormFieldWrapper,
	BImg as QImg,
	BLink as QLink,
	QMenu,
	QModal,
	QModalConfirmDelete,
	QModalSuccess,
	QNav,
	QNavbar,
	BNavbarBrand as QNavbarBrand,
	BNavbarNav as QNavbarNav,
	BNavItem as QNavItem,
	BNavItemDropdown as QNavItemDropdown,
	BNavText as QNavText,
	BOffcanvas as QOffcanvas,
	BOverlay as QOverlay,
	QPagination,
	BPopover as QPopover,
	QProgress,
	BRow as QRow,
	QShoeSizeSelector,
	BSpinner as QSpinner,
	QStepper,
	QTable,
	QTableSimple,
	QTabs,
	BTbody as QTBody,
	BTd as QTd,
	BTh as QTh,
	BThead as QTHead,
	QThumbnail,
	BToast as QToast,
	BToastOrchestrator as QToastOrchestrator,
	BTr as QTr,
	QTreeView,
	QTreeViewItem,
	Quantifier
};
